import axiosIns from '@/libs/axios'

export function getEntityItem({ id, includes }) {
  return axiosIns.get(`/websites/externals/entity-items/${id}`, {
    params: {
      includes,
    },
  })
}

export function getEntityItems({
  entityID, perPage, sortedBy, orderBy, page, search, searchItems, searchJoin, includes,
}) {
  return axiosIns.get('/websites/externals/entity-items', {
    params: {
      entityID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchItems,
      searchJoin,
      includes,
    },
  })
}

export function createEntityItem({
  entity_id,
  data,
}) {
  return axiosIns.post('/websites/externals/entity-items', {
    entity_id,
    data,
  })
}

export function updateEntityItem({
  id,
  data,
}) {
  return axiosIns.patch(`/websites/externals/entity-items/${id}`, {
    data,
  })
}

export function deleteEntityItem(id) {
  return axiosIns.delete(`/websites/externals/entity-items/${id}`)
}

export default {
  getEntityItems,
  getEntityItem,
  createEntityItem,
  updateEntityItem,
  deleteEntityItem,
}
