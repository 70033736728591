<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных
      </h4>
      <div class="alert-body">
        Не найден экземпляр сущности с этим идентификатором.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <app-breadcrumb
      :breadcrumb="breadcrumb"
      :page-title="pageTitle"
    />
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            v-for="field in fields"
            :key="field.id + field.key"
            cols="12"
          >
            <b-form-group
              :label="field.name"
              :label-for="field.key"
              :description="field.description"
            >
              <b-form-input
                v-if="field.type === 'string'"
                :id="field.key"
                v-model.trim="item.data[field.key]"
                placeholder="Введите значение"
                :state="get(validation, [`data.${field.key}`, '0']) ? false : null"
              />
              <b-form-textarea
                v-else-if="field.type === 'text'"
                :id="field.key"
                v-model.trim="item.data[field.key]"
                placeholder="Введите значение"
                :state="get(validation, [`data.${field.key}`, '0']) ? false : null"
              />
              <b-form-checkbox
                v-else-if="field.type === 'boolean'"
                v-model="item.data[field.key]"
                :state="get(validation, [`data.${field.key}`, '0']) ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.data[field.key]">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <image-uploader-media
                v-else-if="field.type === 'image'"
                v-model="item.data[field.key]"
              />
              <images-uploader-media
                v-else-if="field.type === 'images'"
                v-model="item.data[field.key]"
              />
              <file-uploader-media
                v-else-if="field.type === 'file'"
                v-model="item.data[field.key]"
              />
              <files-uploader-media
                v-else-if="field.type === 'files'"
                v-model="item.data[field.key]"
              />
              <!--              <template v-if="field.type === 'image'">-->
              <!--                <pre>{{ item.data[field.key] || 'null' }}</pre>-->
              <!--              </template>-->
              <small
                v-if="!!get(validation, [`data.${field.key}`, '0'])"
                class="text-danger"
              >
                {{ get(validation, [`data.${field.key}`, '0']) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'
import imageUploaderMedia from '@/components/ImageUploaderMedia.vue'
import imagesUploaderMedia from '@/components/ImagesUploaderMedia.vue'
import fileUploaderMedia from '@/components/FileUploaderMedia.vue'
import filesUploaderMedia from '@/components/FilesUploaderMedia.vue'

import vSelect from 'vue-select'
import get from 'lodash/get'
import {
  getCurrentInstance, ref, onMounted, set, computed,
} from 'vue'
import useCrudEdit from '@/composables/useCrudEdit'
import { updateEntityItem as updateEntityItemApi, getEntityItem as getEntityItemApi } from '@/services/main-api/websites/externals/entity-items'
import { getEntity as getEntityApi } from '@/services/main-api/websites/externals/entities'
import { getEntityFields } from '@/services/main-api/websites/externals/entity-fields'
import { getWebsite } from '@/services/main-api/websites/externals/websites'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'

export default {
  name: 'EditWarehouseView',
  components: {
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BTab,
    BTabs,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BAvatar,
    AppBreadcrumb,
    imageUploaderMedia,
    imagesUploaderMedia,
    fileUploaderMedia,
    filesUploaderMedia,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const entity = ref(null)

    const getEntity = async () => {
      try {
        const res = await getEntityApi({
          id: instance.item.entity_id,
        })
        entity.value = res.data.data
      } catch (e) {
        entity.value = null
      }
    }

    const site = ref(null)

    const getSite = async () => {
      try {
        const res = await getWebsite({
          id: entity.value.website_id,
        })
        site.value = res.data.data
      } catch (e) {
        site.value = null
      }
    }

    const fields = ref([])

    const getFields = async () => {
      try {
        const res = await getEntityFields({
          entityID: instance.item.entity_id,
          orderBy: 'sort_order',
          sortedBy: 'asc',
          perPage: 1000,
        })
        fields.value = res.data.data

        // fields.value.forEach(f => {
        //   set(instance.item.data, f.key, '')
        // })
      } catch (e) {
        fields.value = []
      }
    }

    const getItem = async () => {
      try {
        const res = await getEntityItemApi({ id: props.id })
        instance.item = res.data.data

        await Promise.all([
          (async () => {
            await getEntity()
            await getSite()
          })(),
          await getFields(),
        ])
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    // BEGIN Breadcrumb
    const breadcrumb = computed(() => {
      const bItems = [
        {
          text: 'Сайты',
          to: { name: 'sites' },
        },
      ]

      if (site.value) {
        bItems.push({
          text: get(site.value, 'name', ''),
          to: { name: 'sites.external.show', params: { id: get(site.value, 'id', '') } },
        })
      }

      if (entity.value) {
        bItems.push({
          text: `Редактирование элемента ${get(entity.value, 'name', '')}`,
          active: true,
        })
      }
      return bItems
    })
    const pageTitle = computed(() => `Редактирование элемента ${get(entity.value, 'name', '')}`)
    // END Breadcrumb

    const updateEntity = async () => {
      await updateEntityItemApi({
        id: instance.item.id,
        data: instance.item.data,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        data: '',
      },
      validation: {
        rules: {
          // name: 'required',
        },
        customErrorMessages: {
          // 'required.name': 'Укажите название',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Экземпляр был изменен.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      fields,

      clearForm,
      updateItem,

      breadcrumb,
      pageTitle,

      get,
      getItemError,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
