import axiosIns from '@/libs/axios'

export function addFile({ file }, config = {}) {
  const formData = new FormData()
  formData.append('file', file)
  return axiosIns.post('/medialibrary/media-files', formData, config)
}

export default {
  addFile,
}
