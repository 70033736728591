import axiosIns from '@/libs/axios'

export function getEntity({ id, includes }) {
  return axiosIns.get(`/websites/externals/entities/${id}`, {
    params: {
      includes,
    },
  })
}

export function getEntities({
  websiteID, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/websites/externals/entities', {
    params: {
      websiteID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createEntity({
  website_id,
  name,
  key,
  description,
}) {
  return axiosIns.post('/websites/externals/entities', {
    website_id,
    name,
    key,
    description,
  })
}

export function updateEntity({
  id,
  name,
  description,
}) {
  return axiosIns.patch(`/websites/externals/entities/${id}`, {
    name,
    description,
  })
}

export function deleteEntity(id) {
  return axiosIns.delete(`/websites/externals/entities/${id}`)
}

export default {
  getEntities,
  getEntity,
  createEntity,
  updateEntity,
  deleteEntity,
}
