import axiosIns from '@/libs/axios'

export function getEntityField({ id, includes }) {
  return axiosIns.get(`/websites/externals/entity-fields/${id}`, {
    params: {
      includes,
    },
  })
}

export function getEntityFields({
  entityID, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin, includes,
}) {
  return axiosIns.get('/websites/externals/entity-fields', {
    params: {
      entityID,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
      includes,
    },
  })
}

export function createEntityField({
  entity_id,
  sort_order,
  name,
  key,
  description,
  type,
}) {
  return axiosIns.post('/websites/externals/entity-fields', {
    entity_id,
    sort_order,
    name,
    key,
    description,
    type,
  })
}

export function updateEntityField({
  id,
  sort_order,
  name,
  description,
}) {
  return axiosIns.patch(`/websites/externals/entity-fields/${id}`, {
    sort_order,
    name,
    description,
  })
}

export function deleteEntityField(id) {
  return axiosIns.delete(`/websites/externals/entity-fields/${id}`)
}

export default {
  getEntityFields,
  getEntityField,
  createEntityField,
  updateEntityField,
  deleteEntityField,
}
