<template>
  <b-form-group
    class="file-uploader"
    :label="label"
  >
    <!--    <b-alert-->
    <!--      v-if="!files.length && !upload_files.length"-->
    <!--      variant="primary"-->
    <!--      show-->
    <!--    >-->
    <!--      <div class="alert-body">-->
    <!--        Нет ниодного файла-->
    <!--      </div>-->
    <!--    </b-alert>-->
    <b-list-group>
      <b-list-group-item
        v-for="(file, index) in files"
        :key="'f' + index"
        class=""
      >
        <b-row align-v="center">
          <b-col class="file-uploader__name-col">
            <b-link
              :href="file.url"
              target="_blank"
              :title="file.name"
              class="file-uploader__name"
            >
              {{ file.name }}
            </b-link>
            <div
              v-if="file.size"
              class=""
            >
              <small>
                Размер: {{ file.size / 1000 }} КБ
              </small>
            </div>
          </b-col>
          <b-col
            v-if="mode !== 'view'"
            cols="auto"
            class="ml-auto"
          >
            <b-button
              variant="flat-primary"
              class="btn-icon"
              size="sm"
              @click="deleteFile(file)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>
        </b-row>

      </b-list-group-item>

      <b-list-group-item
        v-for="(file, index) in upload_files"
        :key="'uf' + index"
        class=""
      >
        <b-row align-v="center">
          <b-col
            class="file-uploader__name-col"
            cols="12"
          >
            <div
              class="file-uploader__name"
              :title="file.file_name"
            >
              {{ file.file_name }}
            </div>
            <div class="">
              <small>
                Размер: {{ file.file_size / 1000 }} КБ
              </small>
            </div>
          </b-col>
          <b-col
            v-if="file.error"
            cols="12 mt-1"
          >
            <small class="text-danger">{{ file.error }}</small>
          </b-col>
          <b-col
            v-else
            cols="12 mt-1"
          >
            <b-progress
              v-model="file.progress"
              max="100"
              show-value
              animated
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <input
      id="files"
      ref="files"
      type="file"
      :accept="accept"
      @change="handleFilesUpload()"
    >
    <!-- <div
      ref="fileSelect"
      class="file-uploader__select-block"
      @click="changeFiles()"
    >
      Выберите или перетащите файлы
    </div>-->
    <b-button
      v-if="mode !== 'view' && !files.length && !upload_files.length"
      ref="fileSelect"
      variant="outline-primary"
      class="w-100"
      @click="changeFiles()"
    >
      Выберите файл
    </b-button>
  </b-form-group>
</template>

<script>
import {
  BListGroup, BListGroupItem, BLink, BRow, BCol, BButton, BAlert, BFormGroup, BProgress,
} from 'bootstrap-vue'
import { addFile } from '@/services/main-api/medialibrary/media-files'
import { get } from 'lodash'

export default {
  name: 'FileUploaderMedia',
  initialDownloadComplete: false,
  components: {
    BListGroup,
    BListGroupItem,
    BLink,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormGroup,
    BProgress,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    accept: {
      type: String,
      default: '.pdf,.txt,.doc,.docx,.jpg,.jpeg,.png,.tiff,.tif,.webp',
    },
    mode: {
      type: String,
      default: '', // view
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [],
      upload_files: [],
      last_index: 0,
      dragAndDropCapable: false,
    }
  },
  watch: {
    files(newValue) {
      this.$emit('input', newValue[0])
    },
    value(newValue) {
      if (!this.$options.initialDownloadComplete) {
        this.files = newValue ? [newValue] : []
        this.$options.initialDownloadComplete = true
      }
    },
  },
  mounted() {
    if (this.value) {
      this.files = [this.value]
    }
  },
  beforeDestroy() {
  },
  methods: {
    async handleFilesUpload() {
      const uploadedFiles = this.$refs.files.files
      for (let i = 0; i < uploadedFiles.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        this.uploadFile(uploadedFiles[i])
      }
      this.$refs.files.value = ''
    },
    changeFiles() {
      this.$refs.files.click()
    },
    async uploadFile(file) {
      // eslint-disable-next-line no-plusplus
      const index = this.last_index++
      const fileAndProgress = {
        file,
        progress: 0,
        id: index,
        file_name: file.name,
        file_size: file.size,
        content_type: file.type,
        error: null,
      }

      this.upload_files.push(fileAndProgress)
      try {
        const res = await addFile({
          file,
        }, {
          // eslint-disable-next-line func-names
          onUploadProgress: progressEvent => {
            fileAndProgress.progress = parseInt((progressEvent.loaded * 100) / progressEvent.total)
          },
        })
        // this.files.push(res.data)
        this.files.push({
          url: get(res, 'data.data.url'),
          name: get(res, 'data.data.name'),
          title: '',
          description: '',
          size: get(res, 'data.data.size'),
        })
        // url name title description size
        const deletedIndex = this.upload_files.findIndex(i => i.id === index)
        this.upload_files.splice(deletedIndex, 1)
      } catch (e) {
        fileAndProgress.error = get(e, 'response.data.errors.file[0]')
      }
    },
    submitFile() {
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div')
      return (('draggable' in div)
              || ('ondragstart' in div && 'ondrop' in div))
          && 'FormData' in window
          && 'FileReader' in window
    },
    stopEventForListener(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    uploadFilesForListener(e) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        this.uploadFile(e.dataTransfer.files[i])
      }
    },
    async deleteFile(file) {
      const resButton = await this.$swal({
        title: 'Вы уверены?',
        text: `Файл ${file.name} будет удален`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (resButton.value) {
        try {
          const indexDeleteFile = this.files.findIndex(i => i.id === file.id)
          this.files.splice(indexDeleteFile, 1)
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Файл был удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          // deleteFile({
          //   id: file.id,
          // })
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.file-uploader{
  input[type=file]{
    display: none;
  }
  &__select-block{
    background: #ddd;
    padding: 30px 10px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
  }
  &__name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }
  &__name-col{
    min-width: 0;
  }
}
</style>
