import { render, staticRenderFns } from "./FilesUploaderMedia.vue?vue&type=template&id=1a8fc152&scoped=true&"
import script from "./FilesUploaderMedia.vue?vue&type=script&lang=js&"
export * from "./FilesUploaderMedia.vue?vue&type=script&lang=js&"
import style0 from "./FilesUploaderMedia.vue?vue&type=style&index=0&id=1a8fc152&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a8fc152",
  null
  
)

export default component.exports